<template>
  <section class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>OMS</el-breadcrumb-item>
           <el-breadcrumb-item>酒店管家</el-breadcrumb-item>
           <el-breadcrumb-item>工单管理</el-breadcrumb-item>
           <el-breadcrumb-item>工单分类</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
     <el-row class="content-box">
        <!-- 查询框   -->
        <el-row class="search-box">
           <el-row class="search-row">
              <el-row class="search-item">
                 <label>分类名称：</label>
                 <el-select class="width-220" v-model="categoryQ" placeholder="请选择">
                    <el-option
                          v-for="item in categories"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                    </el-option>
                 </el-select>
              </el-row>
              <el-button class="m-bottom-5 bg-gradient" type="primary" icon="el-icon-search" @click="handleQuery(true)" ><span v-text="$t('msg.search')">搜索</span></el-button>
              <el-button class="m-bottom-5" type="primary" icon="el-icon-refresh-right" @click="handleQuery(false)"  plain><span v-text="$t('msg.reset')">重置</span></el-button>
           </el-row>
           </el-row>
        <!-- 主体内容 -->
        <el-row class="table-box">
           <!-- 表格按钮 -->
           <div class="table-head-btn">
              <el-row></el-row>
              <el-row class="right-btn">
                 <el-button  class="m-bottom-5 bg-gradient" type="primary" icon="el-icon-plus" @click="handleAdd()"><span>新建分类</span></el-button>
              </el-row>
           </div>
           <!-- 表格 -->
            <el-table
                ref="mainTable"
                v-loading="loading"
                :data="tableData"
                border fit
                :stripe="true"
                >
              <el-table-column prop="number" label="序号" width="60">
                <template slot-scope="scope">
                  {{scope.$index + 1}}
                </template>
              </el-table-column>
              <el-table-column prop="typeName" label="分类名称" min-width="80"></el-table-column>
              <el-table-column prop="typeDesc" label="分类简介"></el-table-column>
              <el-table-column prop="createTime" label="创建时间"></el-table-column>
              <el-table-column :label="$t('msg.operating')" min-width="140" sortable fixed="right">
                <template slot-scope="scope">
                  <el-button  type="text" icon="el-icon-edit" @click="handleEdit(scope.row)" >
                    <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                  </el-button>
                  <el-button type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="handleDel(scope.row)" >
                    <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
         </el-row>
       <!-- 新增/编辑弹窗 -->
       <el-dialog :title="this.action === 'add' ? '新增分类' : '编辑分类'" :visible.sync="isShow" width="550px">
            <el-form class="form-dialog-box">
               <el-form-item label="工单类型：" required>
                  <el-select   class="width-300" v-model="category" clearable placeholder="请选择">
                     <el-option
                           v-for="item in categories"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                     </el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="分类名称：" required>
                  <el-input   class="width-300" maxlength = '20' v-model="typeName" placeholder="请输入分类名称" clearable></el-input>
               </el-form-item>
               <el-form-item label="分类描述：">
                  <el-input   class="width-300" type="textarea" v-model="typeDesc"  maxlength="55" placeholder="请您描述" ></el-input>
               </el-form-item>
               <el-form-item label="分类code：" required>
                  <el-input  class="width-300"  v-model="typeCode" :disabled="bool" maxlength = '20' placeholder="请输入分类code"></el-input>
               </el-form-item>
               <el-form-item label="排序号：" required>
                  <el-input  class="width-300"  oninput="value=value.replace(/[^\d]/g,'')" maxlength = '20' v-model="sortNo" placeholder="请您排序" clearable></el-input>
               </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
             <el-button  @click="handleReset" v-text="$t('msg.reset')">重置</el-button>
             <el-button class="bg-gradient" type="primary" @click="handleSave()" v-text="$t('msg.save')">保存</el-button>
          </span>
       </el-dialog>
     </el-row>
  </section>
</template>
<script>
   import { urlObj } from '@/api/interface'
   import {hotelMan} from "@/api/interface/oms";
   export default {
      data(){
         return{
            tableData: [],                  // 表格数据
            loading: true,                  // 动画加载效果
            isShow: false,                  // 是否显示弹窗
            action: '',                     // 当前操作行为
            categories: [
               {label:'客房服务',value:'roomService'},
               {label:'突发性任务',value:'suddenTask'},
               // {label:'计划任务',value:'plainTask'},
            ],              // 分类列表
            categoryQ:'',                   // 分类名称
            /* 表单字段 */
            id: '',                         // 分类id
            category: '',                   // 分类类型
            typeName: '',                   // 分类名称
            typeCode:'',                    // 分类编码
            typeDesc:'',                    // 分类描述
            sortNo:'',                      // 排序号
            /* 表单验证提示文本 */
            name_no_null: "分类名称不能为空！",
            sort_no_null: '排序号不能为空！',
            code_no_null:'分类code不能为空',
            add_success: "添加成功！",
            del_success: "删除成功！",
            update_success: "修改成功！",
            confirm: '确定',
            cancel: '取消',
            confirm_remove: '确定移除？',
            prompt: '提示！',
           bool:'',
         }
      },
      mounted() {
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.getCategorise()
      },
      methods: {
         // 获取工单分类列表
         getCategorise(){
            const url = hotelMan.workOrderCategory
            const param = { type:1, parentId: this.categoryQ }
            this.$axios.post(url, param).then(res => {
               this.loading = false
               this.tableData = res.records
            })
         },
         // 搜索分类
         handleQuery(bool){
            if (bool) return this.getCategorise()
            this.categoryQ = ''
         },
         // 添加分类
         handleAdd(){
           this.bool = false
            this.isShow = true
            this.action = 'add'
           this.category = ''
           this.typeName = ''
           this.typeDesc = ''
           this.typeCode = ''
           this.sortNo = ''
           this.id = ''
         },
         // 编辑分类
         handleEdit(row){
           this.bool = true
            this.isShow = true
            this.action = 'edit'
            // 设置表单内容
            this.category = row.parentId
            this.typeName = row.typeName
            this.typeDesc = row.typeDesc
            this.typeCode = row.typeCode
            this.sortNo = row.sortNo
            this.id = row.id
         },
         // 重置表单
         handleReset(){
            this.category = ''
            this.typeName = ''
            this.typeDesc = ''
            this.typeCode = ''
            this.sortNo = ''
         },
         // 保存分类
         handleSave(){
           if (!this.category) {
             this.$alert('工单类型不能为空', '', {
               confirmButtonText: this.confirm,
               type: 'error'
             })
             return
           }

            // 名称不能为空
           if (!this.typeName) {
             this.$alert('分类名称不能为空', '', {
               confirmButtonText: this.confirm,
               type: 'error'
             })
             return
           }
            // 排序号不能为空
            if (!this.sortNo) {
               this.$alert(this.sort_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            // 分类编码不能为空
            if (!this.typeCode) {
               this.$alert('分类code不能为空', '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            let url = hotelMan.Thenewtype
            let param = {
               typeName: this.typeName,
               typeDesc: this.typeDesc,
               type:'1',
               typeCode: this.typeCode,
               sortNo:this.sortNo,
               parentId: this.category
            }
            if(this.action === 'edit') param.id = this.id
            this.$axios.post(url, param).then(() => {
               this.$message({
                  showClose: true,
                  message: this.action === 'add' ? this.add_success : this.update_success,
                  type: 'success'
               })

               this.getCategorise()
            })
         },
         // 关闭弹窗
         handleClose(){
            this.isShow = false
            this.handleReset()
         },
         // 删除分类
         handleDel(row){
            this.$confirm('此操作将永久删除此分类 , 是否继续?', '提示', {
               confirmButtonText: '确定',
               cancelButtonText: '取消',
               type: 'warning'
            }).then(() => {
               const url = hotelMan.delCategory
               const param = { id: row.id }
               this.$axios.post(url, param).then(() => {
                  this.$message({
                     type: 'success',
                     message: '删除成功!'
                  });
                  this.getCategorise()
               })
            }).catch(() => {})
         },
      }
   }
</script>

<style></style>
